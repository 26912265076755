/* Table of Contents */
/* 1. Smaller screen sizes */
/* 1a. Tablet size */
/* 1b. Phone size - landscape */
/* 1c. Phone size - portrait */
/* 2. TOC, nav, and slide arrows */
/* 3. Slide components used across slides */
/* 4. Specific slides */
/* 5. Scrollbars */

/* 1a. Tablet size */
@media screen and (max-width: 1015px) {
  /* 1a. Tablet size - TOC, nav, and slide arrows */
  .desktop-nav {
    display: none;
  }

  .tablet-nav {
    display: flex;
  }

  .slide-arrow {
    margin-top: 30px;
    max-height: 100%;
  }
  .toc-box {
    border-radius: 0%;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  /* 1a. Tablet size - Slide content containers */
  .slide-content-container {
    padding-left: 0;
  }

  .inner-slide-content {
    margin-top: 40px;
    /* -webkit-overflow-scrolling: touch; */
  }

  /* 1a. Tablet size - Slide components used across slides */

  h2 {
    font-size: 26px !important;
    line-height: 38px !important;
  }

  h3 {
    font-size: 22px !important;
    line-height: 30px !important;
  }

  .paragraph-big {
    font-size: 20px !important;
    line-height: 28px !important;
  }

  /* - should have called it photo and content... flex box */
  .photo-and-image {
    flex-direction: column-reverse !important;
    flex-wrap: nowrap !important;
    align-items: center;
    justify-items: center;
    width: 100%;
    max-width: 100%;
  }

  /* - reverses order of the flex-box content - used for photo-and-image box */
  .photo-box-reverse {
    margin-right: 0 !important;
  }

  /* - new margins for when row turns to column at smaller breakpoint */
  .photo-and-image .photo-2 {
    margin-top: 16px;
    margin-bottom: 8px;
    margin-right: 0px !important;
  }

  .photo-and-image .paragraph-big {
    margin-top: 4px;
    text-align: left;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
  }

  /* 1a. tablet size - scrollbar */
  ::-webkit-scrollbar-track {
    /* margin-top: 40px; */
    background-color: #d0d5dd;
    /* -webkit-border-radius: 20px;
    border-radius: 20px; */
    z-index: -10;
  }
}

/* 1b. Phone size - landscape */
@media screen and (max-width: 724px) {
  /* 1b. Phone size landscape - TOC, nav */

  .ham-and-text-phone {
    margin-left: 24px !important;
  }

  .tablet-page-number-wrap {
    position: static;
    width: auto;
    height: auto;
    justify-content: right;
    margin-left: 0%;
    margin-right: 0%;
    padding-right: 32px;
  }

  .tablet-flippr-image {
    display: none !important;
  }

  /* 1b. Phone size landscape - components used across slides */
  .slide-content-container2 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .mobile-hidden {
    display: none !important;
  }

  .paragraph-big {
    text-align: center !important;
    justify-content: center !important;
  }

  .paragraph-big.text-left-important {
    text-align: left !important;
  }

  .photo-and-image .photo-2 {
    min-width: 175px !important;
  }

  /* - change any flex box to col on mobile */
  .mobile-col {
    flex-direction: column !important;
    min-width: 100%;
  }

  .mobile-text-center {
    text-align: center;
    align-items: center;
  }

  /* 1b. Phone size landscape - scrollbar */
  ::-webkit-scrollbar {
    display: none;
  }

  /* 1b. Phone size landscape - specific slides: cover */

  .cover-image-wrap {
    min-width: 100vw !important;
    left: 10vw;
    bottom: 5vh;
    position: fixed !important;
  }

  .cover-image-comp {
    max-height: 35vh;
    margin-top: -40px;
    width: auto !important;
    margin-left: auto;
    margin-right: auto;
    z-index: 20;
  }

  .mobile-cover-text {
    margin-left: 0 !important;
    max-width: 45vw;
    height: 40vh !important;
  }

  /* 1b. Phone size landscape - specific slides: why now */
  .chart-wrap {
    max-width: 70% !important;
    min-width: 150px !important;
    margin-left: -28px !important;
  }

  .logo-in-chart-wrap {
    width: 50px !important;
    margin-top: -5%;
  }

  .logo-in-chart-wrap:last-child {
    margin-top: 0px;
    width: 65px !important;
  }

  .chart-content-row {
    flex-direction: column !important;
    align-items: center;
    padding-right: 0px !important;
  }

  .callout-box.rounded-callout2 {
    width: 240px !important;
    height: 240px !important;
    margin-left: 0px;
  }

  .callout-box.rounded-callout2.neg-margin-left-why-now {
    margin-left: 0px;
    margin-top: 24px;
  }

  .callout-box.rounded-callout3 {
    min-width: 0px !important;
    min-height: 380px !important;
  }

  .callout-box.rounded-callout3.why-now-neg-left {
    left: -1px;
  }

  .bigger-logo-box-phone {
    min-height: 380px !important;
  }

  .tooltip-wrap1 {
    bottom: 115px !important;
    left: -40px !important;
  }

  .tooltip-wrap2 {
    bottom: 120px !important;
    left: -140px !important;
  }

  .tooltip-wrap3 {
    bottom: 140px !important;
    left: -135px !important;
  }

  .tooltip-wrap4 {
    bottom: 150px !important;
    left: -40px !important;
  }

  .tooltip-wrap5 {
    bottom: 155px !important;
    left: -130px !important;
  }

  /* 1b. Phone size landscape - specific slides: Team */
  .bios-col {
    width: 100% !important;
    min-width: 220px !important;
    max-width: calc(100% - 40px);
  }

  /* 1b. Phone size landscape  - specific slides: Market */
  .market-text1 {
    font-size: 26px !important;
    line-height: 34px !important;
  }

  .market-text2 {
    font-size: 18px !important;
    line-height: 20px !important;
  }

  .market-wrap-text-mobile {
    margin-top: 12px;
  }

  .adobe-logo-wrap {
    margin-top: 20px !important;
  }

  .market-flow-callout-mobile {
    min-width: 182px;
    max-width: 182px;
  }

  .market-flow-line3 {
    width: calc(50% - 91px) !important;
  }

  .market-line-grow {
    height: 214px !important;
  }

  .market-line-grow2 {
    height: 230px !important;
  }

  /* 1b. Phone size landscape - specific slides: Wedge */
  .mobile-icon-size {
    min-width: 24px !important;
    max-width: 24px !important;
  }

  .photo-redline {
    margin-left: auto;
    margin-right: auto;
  }

  .mobile-no-shadow {
    background: none !important;
    box-shadow: none !important;
    padding: 0px !important;
  }

  /* 1b. Phone size landscape - specific slides: Business Model */

  .fly-num1 {
    left: 66px !important;
    top: 37.5px !important;
  }
  .fly-num2 {
    left: 128.5px !important;
    top: 60px !important;
  }
  .fly-num3 {
    left: 110px !important;
    bottom: 40px !important;
  }
  .fly-num4 {
    bottom: 58px !important;
    left: 50px !important;
  }

  .flywheel-wrap {
    width: 100% !important;
    background: #ececec;
    /* position: sticky;
        top: 32px; */
  }
  #flywheel-image {
    max-width: 200px !important;
  }
  .fly-hover-parent {
    margin-top: 4px;
    margin-left: 4px;
    margin-right: 4px;
  }
  .fly-hover {
    width: 100% !important;
  }
  .fly-hover-parent {
    width: calc(100% - 8px) !important;
  }

  /* 1b. Phone size landscape - specific slides: Financials */
  .timeline-text {
    font-size: 18px !important;
    line-height: 22px !important;
  }

  .timeline-h-line {
    min-width: calc(35vw - 72px) !important;
    width: calc(35vw - 72px) !important;
  }
  .timeline-arrow-base {
    height: 940px !important;
  }
}

/* 1c. Phone size - portrait */
@media screen and (max-width: 420px) {


  /* 1c. Phone size portrait - specific slides: cover */
  
  /* 1c. Phone size portrait - specific slides: competition */

  .comp-immersive-hide {
    display: none;
  }

  .immersive-box {
    border: none;
  }

  .immersive-show-mobile {
    display: block !important;
  }

  .comp-table-label.firstcol {
    font-size: 16px;
  }

  /* 1c. Phone size portrait - specific slides: why now */

  .shrink-callout-box-mobile {
    max-width: calc(100vw - 88px);
  }
  .photo-4 {
    min-width: 232px;
    margin: 0px;
  }
  .photo-2 {
    min-width: 175px;
    margin: 0px;
  }

  .callout-box.why-now {
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    max-width: calc(100vw - 88px);
  }

  .phone-max-width {
    max-width: calc(100vw - 88px);
  }


  /* 1c. Phone size portrait - specific slides: market size */
  .market-wrap-phone {
    padding-left: 4px;
    padding-right: 4px;
  }

  /* 1c. Phone size portrait - specific slides: Business Model */

  .plan-wrap {
    min-width: calc(100% - 8px) !important;
    max-width: calc(100% - 8px) !important;
    min-height: fit-content !important;
    height: fit-content !important;
  }

  .faq-question {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .faq-text {
    font-size: 16px !important;
    line-height: 24px !important;
  }

  /* 1c. Phone size portrait - specific slides: Fundraising */
  .timeline-arrow-base {
    height: 1270px !important;
  }
}

/* 2. TOC, nav, and slide arrows */
/* - checkbox used for CSS hamburger menu not displayed */
.side-menu {
  display: none;
}

/* 2. TOC, nav, and slide arrows (continued)*/
/* - hamburger icon */
.hamb {
  cursor: pointer;
}
.hamb-line {
  background: white;
  display: block;
  height: 2px;
  position: relative;
  width: 16px;
  border-radius: 20%;
}

.hamb-line::before,
.hamb-line::after {
  background: white;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
  border-radius: 20%;
}
.hamb-line::before {
  top: 5px;
}
.hamb-line::after {
  top: -5px;
}

/* 2. TOC, nav, and slide arrows (continued)*/
/* - toggled hamburger icon */
.side-menu:checked ~ .hamb .hamb-line {
  background: transparent;
}
.side-menu:checked ~ .hamb .hamb-line::before {
  transform: rotate(-45deg);
  top: 0;
}
.side-menu:checked ~ .hamb .hamb-line::after {
  transform: rotate(45deg);
  top: 0;
}

/* 2. TOC, nav, and slide arrows (continued)*/
/* - toggled TOC */
.side-menu:not(:checked) ~ .hamb .toc-wrap {
  display: none;
}

.side-menu:checked ~ .hamb .toc-wrap {
  display: block;
  position: fixed;
  top: 40px;
  left: 0px;
}

/* 2. TOC, nav, and slide arrows (continued)*/
/* - Desktop toc stylings */
.toc-text:last-child {
  padding-bottom: 8px;
}

hr {
  border-color: #101828;
}

.toc-text {
  font-size: 16px;
  line-height: 18px;
  border-color: #101828;
}

.toc-text-active > span:last-child {
  display: inline;
  color: #f2f4f7;
}

.toc-text:hover > span:last-child {
  display: hidden;
}

.toc-text:hover > span:first-child {
  text-decoration: underline;
}

/* 3. Slide content components reused across slides */
.inner-slide-content {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: left;
  padding-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
}

h2,
h3,
h4,
h5,
h6,
p {
  text-align: left;
  margin-top: 0px;
}

h2 {
  font-size: 32px;
  line-height: 44px;
  font-weight: 600;
  color: #101828;
  margin-bottom: 24px;
}

h3 {
  font-size: 26px;
  line-height: 34px;
  font-weight: 400;
  color: #101828;
}

.paragraph-big {
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
}

/* - Should have said photo and text... formats photo and text in a row */
.photo-and-image {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
}

.photo-and-image h3,
.callout-box p {
  align-items: center;
  display: flex;
}

.photo-box-reverse {
  flex-direction: row-reverse;
  gap: 16px;
  margin-right: -16px;
}

/* 3. Slide content components reused across slides (continued)*/
/* - Other reused components  */
.callout-box {
  background: #f2f2f2;
  padding: 16px;
  margin-bottom: 24px;
  border-radius: 8px;
  box-shadow: 2px 2px 3px #98a2b3;
  width: 100%;
  position: relative;
  justify-content: left;
  display: flex;
  flex-direction: row;
  z-index: 0;
}

.callout-box.rounded-callout2 {
  margin-bottom: 0px;
  border-radius: 100%;
  width: 250px;
  height: 250px;
  position: relative;
}

.circle-number-wrap {
  background: #f5f5f5;
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 2px 2px 3px #d0d5dd;
  margin-right: 16px;
}

.circle-number {
  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
  color: #4939df;
}

.circle-check-wrap {
  background: #12b76a;
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  box-shadow: 2px 2px 3px #d0d5dd;
  margin-right: 16px;
}

.circle-check {
  color: #f9fafb;
}

.divider8 {
  height: 8px;
  width: 100%;
}

.divider16 {
  height: 16px;
  width: 100%;
}

.divider24 {
  height: 24px;
  width: 100%;
}

.divider32 {
  height: 32px;
  width: 100%;
}

.divider40 {
  height: 40px;
  width: 100%;
}

/* 4. Specific slides */
/* Cover */

.slide-content-container {
  background: linear-gradient(45deg, #ececec, #ececec 75%, #ececec);
}

.vector-slide-content {
  position: fixed;
  top: 0;
  right: 0;
}

.vector-rhs {
  position: absolute;
  overflow: clip;
  z-index: 0;
  right: 0px;
  top: 0;
  width: 50vw;
  height: 100vh;
  clip-path: polygon(20% 100%, 50% 0%, 100% 0%, 100% 100%);
  background: linear-gradient(
    162deg,
    #4939df,
    #4939df 40%,
    rgba(73, 57, 223, 0.34)
  );
}

.bio-photo-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  padding: 8px;
  background: #f2f2f2;
  box-shadow: 2px 2px 3px #98a2b3;
  border-radius: 8px;
}

.bio-photo-wrap {
  width: 96px;
  min-width: 96px;
  height: 96px;
  border-radius: 100%;
  border-color: #4939df;
  border-width: 2px;
  overflow: hidden;
}

/* 4a. Problem */
.photo-1 {
  margin-bottom: 40px;
}

.photo-and-image .photo-2 {
  max-width: 200px;
  min-width: 200px;
  width: 200px;
  height: auto;
  margin-left: 0;
  margin-right: 24px;
}

/* 4b. Solution */

.photo-3 {
  margin-bottom: 40px;
}

.callout-box.stay-left,
.paragraph-big.stay-left {
  justify-content: left;
  align-items: center;
  text-align: left !important;
}

.callout-box.why-now {
  justify-content: center;
  align-items: center;
}

/* 4c. Team page */
.bios-col {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 45%;
  min-width: 280px;
}

.bio-notfirst-margin {
  margin-top: 32px;
}

/* 4d. Why now */

.photo-4 {
  margin-bottom: 0px;
}

.chart-content-row {
  clear: both;
  height: max-content;
  min-height: 200px;
  flex-wrap: nowrap;
  align-items: center;
}

/* -chart on why now page */
.chart-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 300px;
  height: 313px;
  min-height: 313px;
  max-height: 313px;
  min-width: 200px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
}

.chart-column-stack {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.chart-column-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.chart-column-wrap2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

/* -still chart on why now page... */

.chart-x-axis {
  width: 100%;
  height: 2px;
  min-height: 2px;
  background-color: #667085;
}

.column1-stack1 {
  position: relative;
  width: 100%;
  height: 15px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #7cd4fd;
}

.column1-stack2 {
  position: relative;
  width: 100%;
  height: 3px;
  min-width: 0px;
  background-color: #4939df;
}

.column2-stack1 {
  position: relative;
  width: 100%;
  height: 130px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #7cd4fd;
}

.column2-stack2 {
  position: relative;
  width: 100%;
  height: 87px;
  background-color: #4939df;
}

.chart-label-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-column-gap: 4px;
  grid-row-gap: 0px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: #101828;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
}

/* -still chart on why now page... */

.chart-axis-text {
  margin-top: 4px;
  margin-bottom: 0px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: #101828;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
}

.hover-opacity {
  opacity: 1;
  cursor: help;
}

.hover-opacity:hover {
  opacity: 0.75;
}

.logo-column {
  position: absolute;
  left: 90%;
  right: auto;
  height: 100%;
  padding-top: 48px;
}

.logo-in-chart-wrap {
  width: 75px;
  height: 40px;
}

.text-replace-with-logo {
  text-align: center;
}

.logo-in-chart-spacer {
  height: 90px;
}

/* -still chart on why now page... */

.tooltip {
  position: absolute;
  z-index: 20;
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  max-width: 100px;
  min-height: 32px;
  min-width: 100px;
  margin-left: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background-color: #101828;
}

.paragraph-tooltip {
  margin-bottom: 8px;
  color: #344054;
  line-height: 28px;
  text-align: left;
}

.paragraph-tooltip.tooltip-text {
  display: block;
  margin: 4px 6px;
  background-color: #101828;
  color: #fcfcfd;
  opacity: 1 !important;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
}

.tooltip-arrow {
  position: absolute;
  left: 50%;
  bottom: 0px;
  z-index: -1;
  display: none;
  width: 10px;
  height: 10px;
  margin: auto auto -4px;
  border-radius: 2px;
  background-color: #343a48;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.text-span-2 {
  text-decoration: underline;
}

/* -still chart on why now page... */

.tooltip-wrap1 {
  position: absolute;
  top: auto;
  bottom: 120px;
  left: -24px;
  display: block;
  height: 10px;
  max-width: none;
  min-width: 100px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  mix-blend-mode: normal;
}

.tooltip-wrap2 {
  position: absolute;
  top: auto;
  bottom: 120px;
  left: -140px;
  display: block;
  height: 10px;
  max-width: none;
  min-width: 100px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  mix-blend-mode: normal;
}

.tooltip-wrap3 {
  position: absolute;
  top: auto;
  bottom: 140px;
  left: -130px;
  display: block;
  height: 10px;
  max-width: none;
  min-width: 100px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  mix-blend-mode: normal;
}

.tooltip-wrap4 {
  position: absolute;
  top: auto;
  bottom: 150px;
  left: -24px;
  display: block;
  height: 10px;
  max-width: none;
  min-width: 100px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  mix-blend-mode: normal;
}

.tooltip-wrap5 {
  position: absolute;
  top: auto;
  bottom: 150px;
  left: -135px;
  display: block;
  height: 10px;
  max-width: none;
  min-width: 100px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  mix-blend-mode: normal;
}

.tooltip-hover {
  display: none;
}

.column1-stack1:hover .tooltip-hover,
.column1-stack2:hover .tooltip-hover,
.column2-stack1:hover .tooltip-hover,
.column2-stack2:hover .tooltip-hover {
  display: block;
}

/* 4e. Competition */

.comp-table-row1 {
  background: #f9fafb;
  position: relative;
}

.comp-table-row2 {
  position: relative;
}

.comp-table-label {
  padding-top: 24px;
  padding-bottom: 8px;
  text-align: left;
  font-weight: 500;
  line-height: 24px;
  font-size: 17px;
  vertical-align: bottom;
  text-decoration: underline;
}

.comp-table-header {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 4px;
  padding-right: 0px;
  font-size: 18px;
  color: #101828;
  font-weight: 600;
  vertical-align: bottom;
}

.comp-table-header:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  /* border-bottom: 2px solid #475467; */
  border-radius: 1px;
}

.table-first-row:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: -2px 0 0 0 #ececec, 2px 0 0 0 #ececec;
}

.comp-table-row-header {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 4px;
}

.comp-table-cell {
  text-align: center;
  padding: 8px;
}

.comp-table-logo {
  width: 72px;
}

.face-smile {
  height: 32px;
  color: #12b76a;
}

.face-frown {
  height: 32px;
  color: #d92d20;
}

.comp-table-check {
  height: 32px;
  color: #101828;
}

.tooltip-wrap6 {
  position: absolute;
  z-index: 20;
  bottom: 28px;
  left: 30px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  mix-blend-mode: normal;
}

.tooltip-wrap6 .tooltip .paragraph-tooltip.tooltip-text {
  font-weight: 500;
  color: #fcfcfd;
}

tr.hover-opacity:hover .tooltip-hover {
  display: block;
}

/* 4f. Wedge */
/* 4g. Market Size */

.market-flow-line1 {
  width: calc(50% - 98px);
  height: 2px;
  background: #4939df;
}

.market-flow-line2 {
  width: 2px;
  height: 90px;
  margin-top: 90px;
  background: #4939df;
}

.market-flow-line3 {
  width: calc(50% - 115px);
  height: 2px;
  background: #4939df;
}

.market-flow-line4 {
  width: calc(50% - 119px);
  height: 2px;
  background: #4939df;
}

.triangle-market {
  width: 10px;
  height: 10px;
  margin-top: -4px;
  background: #4939df;
  clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
  rotate: 90deg;
}

.triangle-market2 {
  width: 100px;
  height: 50px;
  border-radius: 6px;
  margin-top: -4px;
  margin-left: auto;
  margin-right: auto;
  background: #4939df;
  clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
  rotate: 180deg;
}

.market-arrow-body {
  width: 40px;
  height: 100px;
  background: linear-gradient(
    180deg,
    rgba(73, 57, 223, 0),
    #4939df 95%,
    #4939df
  );
  margin-left: auto;
  margin-right: auto;
}

/* 4h. Business Model */

.fly-number {
  width: 24px;
  height: 24px;
  background: #f2f2f2;
  box-shadow: 2px 2px 3px #98a2b3;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 600;
  color: #4939df;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  transition: all 0.1s ease-out;
}
.fly-number2 {
  width: 32px;
  min-width: 32px;
  height: 32px;
  /* background: #F2F2F2;    
    box-shadow: 1px 1px 2px #98A2B3;
    border-radius: 4px; */
  font-size: 18px;
  font-weight: 600;
  color: #4939df;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.fly-hover:hover {
  border: 2px solid #4939df !important;
  cursor: help;
  padding: 6px !important;
}

.plan-wrap {
  min-height: 400px;
  max-width: 280px;
  min-width: 280px;
}
.plan-lower-wrap {
  margin-top: 16px;
}
.check-list-item {
  margin-bottom: 8px;
  margin-left: 8px;
  padding-left: 28px;
  background-image: url("/public/fa-check-green.png");
  background-position: 0% 5px;
  background-size: 15px;
  background-repeat: no-repeat;
  background-clip: border-box;
  font-size: 16px;
  line-height: 22px;
  color: #101828;
}
.check-list-item.x {
  background-image: url("/public/fa-x.png");
  background-position: 0% 7px;
  background-size: 12px;
}

.faq-check-input {
  display: none;
}
.faq-caret {
  transition: all 0.2s ease-out;
}
.faq-check-input:checked ~ .faq-label .faq-caret {
  transform: rotate(90deg);
}
.faq-label {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #1d2939;
  border-radius: 6px 6px 0px 0px;
  cursor: pointer;
}
.faq-label:hover {
  background: #101323;
}
.faq-upper-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 6px 6px 0px 0px;
  gap: 12px;
}
.faq-lower-wrap {
  display: none;
  width: 100%;
  background: #f9fafb;
  border-left: 1px solid #98a2b3;
  border-bottom: 1px solid #98a2b3;
  border-right: 1px solid #98a2b3;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.faq-check-input:checked ~ .faq-label .faq-lower-wrap {
  display: block;
}
.faq-question {
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  color: #fcfcfd;
}
.faq-text {
  width: 100%;
  color: #101828;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
}

/* 4i. Traction */
/* 4j. Financials */

.triangle-timeline {
  min-width: 40px;
  width: 40px;
  height: 40px;
  background: #4939df;
  clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
  rotate: 180deg;
}
.timeline-arrow-base {
  min-width: 10px;
  width: 10px;
  height: 700px;
  background: #4939df;
  margin-left: 15px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.timeline-h-line {
  margin-top: 12px;
  height: 2px;
  min-width: 128px;
  width: 128px;
  background: #98a2b3;
  border-radius: 2px;
  margin-right: 4px;
}
.timeline-text {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  width: 100%;
}

/* 5. Scrollbars */
::-webkit-scrollbar {
  width: 15px;
  z-index: 50;
}

::-webkit-scrollbar-track {
  background-color: #d0d5dd;
  /* -webkit-border-radius: 20px; */
  /* border-radius: 20px; */
  z-index: 50;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background: #98a2b3;
  z-index: 50;
}
